import React from "react";

const PricingBanner = () => {
  return (
    <>
      <div className="pricing-banner">
        <div className="pricing-banner-section"></div>
      </div>
    </>
  );
};

export default PricingBanner;
