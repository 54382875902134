import React from 'react'
import EmailSMSTemplate from './EmailSMSTemplate'

const EmailSMSTemplatesIndex = () => {
  return (
    <div className='row'>
      <EmailSMSTemplate/>
    </div>
  )
}

export default EmailSMSTemplatesIndex
