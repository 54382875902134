import React from 'react'
import CampaignSetupMenu from './CampaignSetupMenu'

const CampaingSetupIndex = () => {
  return (
    <div>
      <CampaignSetupMenu/>
    </div>
  )
}

export default CampaingSetupIndex
