import React from "react";
import SubscriptionPrice from "./SubscriptionPrice";

const ContentManagementIndex = () => {
  return (
    <div className="application-dashboard-section">
      <SubscriptionPrice />
    </div>
  );
};
export default ContentManagementIndex;
