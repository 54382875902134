import React from "react";

const UnreadAdminNotifications = () => {
  return (
    <div>
      <h5>Unread page</h5>
    </div>
  );
};

export default UnreadAdminNotifications;
