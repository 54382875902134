import React from 'react'

const DraftCustomizableAlerts = () => {
  return (
    <div>
      <h5>Draft Page</h5>
    </div>
  )
}

export default DraftCustomizableAlerts
