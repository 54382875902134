import React from "react";
import { FaRegSmile } from "react-icons/fa";
import { ImSad } from "react-icons/im";
import { MdOutlineEventNote } from "react-icons/md";
import { TfiCommentAlt } from "react-icons/tfi";
const cardData = [
  {
    icon: TfiCommentAlt,
    title: "Total FeedBAck",
    value: "2900",
    bgColor: "#afa8ff",
    textColor: "#ffffff",
  },
  {
    icon: MdOutlineEventNote ,
    title: "Feedback Collected",
    value: "120",
    bgColor: "#ffa0a9",
    textColor: "#ffffff",
  },

  {
    icon: FaRegSmile ,
    title: "Positive Feedback",
    value: "50",
    bgColor: "#ffcb64",
    textColor: "#ffffff",
  },
  {
    icon:ImSad,
    title: "Negative Feedback",
    value: "50",
    bgColor: "#85bbff",
    textColor: "#ffffff",
  },
  
 
];

const ResponseDashboardCards = () => {
  return (
      <div className="row">
        {cardData.map(
          ({ icon: Icon, title, value, bgColor, textColor }, index) => (
            <div key={index} className="col-lg-3 mb-4">
              <div className="application-dashboard-card">
                <div className="card-body d-flex align-items-center">
                  <div
                    className="icon-wrapper p-2 rounded-circle me-3"
                    style={{ backgroundColor: bgColor, color: textColor }}
                  >
                    <Icon className="" size={22} />
                  </div>
                  <div>
                    <h6 className="cards-subtitle mb-2">{title}</h6>
                    <h2 className="cards-title mb-0">{value}</h2>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
  );
};

export default ResponseDashboardCards;
