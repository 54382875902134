import React from 'react'
import RBACPermissionList from './RBACPermissionList'

const RBACPermissionIndex = () => {
  return (
    <div>
      <RBACPermissionList/>
    </div>
  )
}

export default RBACPermissionIndex
