import React from 'react'
import AuditTrailsTable from './AuditTrailsTable'

const AuditTrailsIndex = () => {
  return (
    <div>
      <AuditTrailsTable/>
    </div>
  )
}

export default AuditTrailsIndex
