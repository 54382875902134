import React from 'react'

const UnsolvedUserNegativeFeedback = () => {
  return (
    <div>
      <h5>UnSolved page</h5>
    </div>
  )
}

export default UnsolvedUserNegativeFeedback
