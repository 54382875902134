import React from "react";

const ContactBanner = () => {
  return (
    <>
      <div className="contact-banner-section">
        <div className="container">
          <center>
            <h2>Contact KC (Know Connections)</h2>
            <p className="subtitle">
              Reach out to KC (Know Connections) for any inquiries, feature
              requests, or support in managing your business contacts. <br /> Let’s KC
              (Know Connections) how we can support your professional networking
              goals.{" "}
            </p>
          </center>
        </div>
      </div>
    </>
  );
};

export default ContactBanner;
