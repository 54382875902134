import React from "react";
import { Helmet } from "react-helmet";
import "./policy.css";
const CancellationPolicy = () => {
  return (
    <>
      <Helmet>
        <title>
          Cancellation and Refund Policy - KC (Know Connections) | Digital Card
          Solutions
        </title>
        <meta
          name="privacy policy"
          content="Learn more about KC (Know Connections) and our mission to provide innovative digital card solutions. Discover how we offer 1000+ unique card designs and our commitment to enhancing your card-making experience."
        />
        <meta
          name="keywords"
          content="About KC (Know Connections), digital card solutions, KC (Know Connections) mission, digital card designs, card-making innovation, company background, KC (Know Connections) team, digital cards overview"
        />
      </Helmet>

      <div className="main-wrapper">
        <div className="about-banner-section">
          <div className="container">
            <h2>Cancellation and Refund Policy</h2>
            {/* <p className="subtitle">Our journey to simplify and enhance business connections for everyone.</p> */}
            <div className="banner-image-container"></div>
          </div>
        </div>
        <div className="privacy-policy-section p-4">
          <div className="container">
            <h2 className="text-center font-bold text-2xl">
              Cancellation and Refund Policy
            </h2>

            <p className="mt-4">
              At KC (Know Connections), we strive to provide a seamless
              experience for our users. This Cancellation and Refund Policy
              outlines the terms and conditions under which cancellations and
              refunds may be processed. By using our services, you agree to the
              terms outlined in this policy.
            </p>

            <p className="mt-4">
              If you have any questions, feel free to reach us at:
            </p>
            <ul className="list-none mt-2 space-y-1">
              <li>
                📧 Email:{" "}
                <a
                  href="mailto:contact@knowconnections.com"
                >
                  contact@knowconnections.com
                </a>
              </li>
              <li>📞 Phone: +91 8792976734</li>
            </ul>

            <div className="mt-6">
              <h2 className="font-semibold text-xl">1. Cancellation Policy</h2>

              <h3 className="mt-4 font-medium">
                1.1 Subscription Cancellations
              </h3>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>
                  You can cancel your subscription anytime through your account
                  settings.
                </li>
                <li>
                  Cancellations take effect at the end of your current billing
                  cycle.
                </li>
              </ul>

              <h3 className="mt-4 font-medium">1.2 Enterprise Plans</h3>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>
                  Enterprise plan cancellations require a 30-day prior notice.
                </li>
              </ul>

              <h3 className="mt-4 font-medium">1.3 Trial Periods</h3>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>
                  You may cancel anytime during the free trial to avoid charges.
                </li>
              </ul>

              <h3 className="mt-4 font-medium">
                1.4 Improper Use or Breach of Terms
              </h3>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>
                  We reserve the right to suspend or cancel your subscription
                  without notice for policy violations.
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h2 className="font-semibold text-xl">2. Refund Policy</h2>

              <h3 className="mt-4 font-medium">2.1 Refund Eligibility</h3>
              <p className="mt-2">
                Refunds may be issued in the following cases:
              </p>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Billing errors resulting in incorrect charges.</li>
                <li>Technical issues preventing access to the service.</li>
              </ul>

              <h3 className="mt-4 font-medium">2.2 Non-Refundable Cases</h3>
              <p className="mt-2">
                No refunds will be provided in the following situations:
              </p>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Cancellation after the billing cycle starts.</li>
                <li>Partial use of a monthly or annual subscription.</li>
                <li>Enterprise plans (as per agreement terms).</li>
              </ul>

              <h3 className="mt-4 font-medium">2.3 Refund Process</h3>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>
                  Request a refund by emailing us at{" "}
                  <a
                    href="mailto:contact@knowconnections.com"
                  >
                    contact@knowconnections.com
                  </a>
                  .
                </li>
                <li>
                  Refund requests will be reviewed within 7 business days.
                </li>
                <li>
                  Approved refunds will be processed within 14 business days.
                </li>
                <li>
                  Refunds will be credited to the original payment method.
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h2 className="font-semibold text-xl">3. Payment Disputes</h2>
              <p className="mt-2">
                If you believe you were charged incorrectly or wish to dispute a
                payment, please contact our support team at
                <a
                  href="mailto:contact@knowconnections.com"
                  className="text-blue-500"
                >
                  {" "}
                  contact@knowconnections.com
                </a>
                or call 📞 +91 8792976734.
              </p>
            </div>

            <div className="mt-6">
              <h2 className="font-semibold text-xl">
                4. Changes to This Policy
              </h2>
              <p className="mt-2">
                KC (Know Connections) reserves the right to update this
                Cancellation and Refund Policy at any time. Changes will be
                posted on this page, and continued use of the service
                constitutes agreement to the updated terms.
              </p>
            </div>

            <div className="mt-8">
              <h2 className="font-semibold text-xl">5. Contact Us</h2>
              <p className="mt-2">
                For any queries or concerns related to cancellations or refunds,
                please contact us:
              </p>

              <ul className="list-none mt-3 space-y-2">
                <li>
                  📧 Email:{" "}
                  <a
                    href="mailto:contact@knowconnections.com"
                  >
                    contact@knowconnections.com
                  </a>
                </li>
                <li>📞 Phone: +91 8792976734</li>
                <li>
                  📍 Address: Opp HDFC ATM, First Floor, No.1/1, T C Palya Main
                  Road, Sannathammanahalli, K R Puram, Bengaluru, Karnataka,
                  560036
                </li>
              </ul>
            </div>

            <p className="mt-6 text-center font-medium">
              Thank you for choosing KC (Know Connections). We are here to
              ensure your satisfaction and trust!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationPolicy;
